@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply custom styles */
@layer base {

  /* Apply default font style */
  html {
    @apply font-text;
    @apply text-lg;
    @apply text-ci-gray;
    /* min-width: 240px; */
  }

  input, select, textarea {
    @apply font-text;
  }

  h1, h2, h3, h4, h5 {
    @apply font-headline;
    @apply font-extrabold;
    @apply tracking-tight;
  }

  h4 {
    @apply text-lg;
    @apply lg:text-xl;
    @apply lg:leading-9;
    @apply whitespace-pre-line;
  }

  * + h4 {
    @apply pt-7;
  }

  p + p {
    @apply pt-7;
  }

  .markdown a {
    @apply text-ci-blue;
    @apply hover:text-ci-orange;
  }

  .markdown ul {
    @apply ml-6;
    @apply pl-1;
    @apply py-7;
    @apply list-square;
  }
}

/* Add custom fonts */
@layer base {
  /* nunito-sans-regular - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/nunito-sans-v6-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./fonts/nunito-sans-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./fonts/nunito-sans-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('./fonts/nunito-sans-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('./fonts/nunito-sans-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./fonts/nunito-sans-v6-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
  }

  /* nunito-sans-600 - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/nunito-sans-v6-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./fonts/nunito-sans-v6-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./fonts/nunito-sans-v6-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
        url('./fonts/nunito-sans-v6-latin-600.woff') format('woff'), /* Modern Browsers */
        url('./fonts/nunito-sans-v6-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./fonts/nunito-sans-v6-latin-600.svg#NunitoSans') format('svg'); /* Legacy iOS */
  }

  /* nunito-sans-800 - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    src: url('./fonts/nunito-sans-v6-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./fonts/nunito-sans-v6-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./fonts/nunito-sans-v6-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
        url('./fonts/nunito-sans-v6-latin-800.woff') format('woff'), /* Modern Browsers */
        url('./fonts/nunito-sans-v6-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./fonts/nunito-sans-v6-latin-800.svg#NunitoSans') format('svg'); /* Legacy iOS */
  }

  /* spectral-700 - latin */
  @font-face {
    font-family: 'Spectral';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/spectral-v7-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./fonts/spectral-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./fonts/spectral-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('./fonts/spectral-v7-latin-700.woff') format('woff'), /* Modern Browsers */
        url('./fonts/spectral-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./fonts/spectral-v7-latin-700.svg#Spectral') format('svg'); /* Legacy iOS */
  }
}

html, body, #root {
  height: 100%;
  min-height: 100%;
  min-width: 320px;
}
